var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$can('manage', 'user') || _vm.$can('manage', 'all') ? _c('div', [_c('b-row', {
    staticClass: "py-1",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "5",
      "lg": "8"
    }
  }, [_c('h2', {
    staticClass: "mb-2 mb-md-0"
  }, [_vm._v(" User ")])]), _c('b-col', {
    attrs: {
      "md": "7",
      "lg": "4"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Cari Email/Perusahaan"
    },
    model: {
      value: _vm.searchKeyword,
      callback: function callback($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  })], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('b-card-header', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Daftar Akun User")]), _c('b-row', [_c('b-col', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.new-user",
      modifiers: {
        "new-user": true
      }
    }],
    staticClass: "action-button",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v(" Tambah Pengguna")])], 1)], 1), _c('b-col', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.new-data-excel",
      modifiers: {
        "new-data-excel": true
      }
    }],
    staticClass: "action-button mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DatabaseIcon"
    }
  }), _c('span', [_vm._v(" Import Account Jalur Darat")])], 1)], 1), _c('b-col', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.new-data-excel-river",
      modifiers: {
        "new-data-excel-river": true
      }
    }],
    staticClass: "action-button mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DatabaseIcon"
    }
  }), _c('span', [_vm._v(" Import Account Jalur Sungai")])], 1)], 1)], 1)], 1), _vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-table', {
    ref: "usersTable",
    staticClass: "m-0",
    attrs: {
      "id": "table-transition",
      "fields": _vm.fields,
      "items": _vm.users,
      "no-border-collapse": true,
      "responsive": "",
      "hover": "",
      "caption-top": "",
      "show-empty": "",
      "primary-key": "number",
      "bordered": "",
      "tbody-transition-props": _vm.transProps
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-0 py-2 text-center"
        }, [_vm._v("Tidak ada data")])];
      },
      proxy: true
    }, {
      key: "cell(is_active)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          staticClass: "custom-control-primary",
          attrs: {
            "checked": data.item.is_active,
            "name": "check-button",
            "switch": ""
          },
          on: {
            "change": function change($event) {
              return _vm.updateIsActive(data.item);
            }
          }
        }, [_c('span', {
          staticClass: "switch-icon-left"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserCheckIcon"
          }
        })], 1), _c('span', {
          staticClass: "switch-icon-right"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserMinusIcon"
          }
        })], 1)])];
      }
    }, {
      key: "cell(type_account)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "href": "#",
            "variant": _vm.variantType(data.item.type_account)
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', [_vm._v(_vm._s(data.item.type_account) + " ")])], 1)];
      }
    }, {
      key: "cell(role)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "href": "#",
            "variant": _vm.variantRole(data.item.role)
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SettingsIcon"
          }
        }), _c('span', [_vm._v(_vm._s(data.item.role) + " ")])], 1)];
      }
    }, {
      key: "cell(abilities)",
      fn: function fn(data) {
        return [_vm._l(data.item.abilities, function (item, index) {
          return _c('sal-access', {
            key: index
          }, [_c('b-badge', {
            attrs: {
              "href": "#",
              "variant": "light-secondary"
            }
          }, [_c('feather-icon', {
            staticClass: "mr-25",
            attrs: {
              "icon": "DatabaseIcon"
            }
          }), _c('span', [_vm._v(_vm._s(item.action) + " " + _vm._s(item.subject))])], 1), _vm._v("   ")], 1);
        }), _vm._v("   ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.edit-user",
            modifiers: {
              "edit-user": true
            }
          }],
          on: {
            "click": function click($event) {
              return _vm.prepareUpdate(data);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-primary",
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "text-primary align-middle ml-50"
        }, [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.delete-user",
            modifiers: {
              "delete-user": true
            }
          }],
          on: {
            "click": function click($event) {
              return _vm.prepareDelete(data);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', {
          staticClass: "text-danger align-middle ml-50"
        }, [_vm._v("Delete")])], 1)], 1)];
      }
    }], null, false, 263332112)
  }), _c('b-card-footer', [_c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "mb-2 mb-sm-0",
    attrs: {
      "sm": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.pagination.amountOptions
    },
    model: {
      value: _vm.pagination.selectedAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "selectedAmount", $$v);
      },
      expression: "pagination.selectedAmount"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-center justify-content-sm-end",
    attrs: {
      "col": ""
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0",
    attrs: {
      "total-rows": _vm.pagination.totalItems,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item",
      "per-page": _vm.pagination.selectedAmount
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "delete-user",
      "title": "Hapus",
      "cancel-variant": "secondary",
      "cancel-title": "Tidak",
      "ok-title": "Iya",
      "ok-variant": "danger",
      "body-class": "py-2",
      "footer-class": "d-flex justify-content-center",
      "centered": ""
    },
    on: {
      "ok": _vm.onDelete
    }
  }, [_c('b-card-text', {
    staticClass: "text-center"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Hapus data " + _vm._s(_vm.form.deleteMessage) + " ?")])])], 1), _c('b-modal', {
    attrs: {
      "id": "new-user",
      "title": "Tambah User",
      "body-class": "py-2",
      "size": "lg",
      "hide-footer": ""
    }
  }, [_c('b-card-text', [_c('validation-observer', {
    ref: "newUserForm"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan",
      "label-for": "perusahaan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Perusahaan",
      "vid": "perusahaan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "perusahaan",
            "type": "text",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.add.perusahaan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "perusahaan", $$v);
            },
            expression: "form.add.perusahaan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3556913113)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "nama"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nama Lengkap",
      "vid": "nama",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "nama",
            "type": "text",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.add.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "full_name", $$v);
            },
            expression: "form.add.full_name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1952100849)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "vid": "email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "name": "email",
            "autocomplete": "off",
            "state": errors.length > 0 ? false : null,
            "placeholder": "user@sal.com"
          },
          model: {
            value: _vm.form.add.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "email", $$v);
            },
            expression: "form.add.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2658257085)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor HP",
      "label-for": "phone"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor HP",
      "vid": "phone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "phone",
            "type": "number",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.add.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "phone", $$v);
            },
            expression: "form.add.phone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 447832135)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Group Account",
      "label-for": "group_account"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "group_account",
      "vid": "group_account"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "suggestions": [{
              data: _vm.form.groups
            }],
            "limit": 7,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Group Account'
            },
            "state": errors.length > 0 ? false : null,
            "get-suggestion-value": _vm.getSuggestionValue
          },
          on: {
            "input": _vm.searchGroups
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var suggestion = _ref6.suggestion;
              return [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.name))])];
            }
          }], null, true),
          model: {
            value: _vm.form.add.group_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "group_name", $$v);
            },
            expression: "form.add.group_name"
          }
        })];
      }
    }], null, false, 562095703)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type Account",
      "label-for": "type_account"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "type_account",
      "reduce": function reduce(type_account) {
        return type_account.label;
      },
      "options": _vm.form.type_account
    },
    model: {
      value: _vm.form.add.type_account,
      callback: function callback($$v) {
        _vm.$set(_vm.form.add, "type_account", $$v);
      },
      expression: "form.add.type_account"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hak Akses",
      "label-for": "role"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Hak Akses",
      "vid": "role",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-select', {
          attrs: {
            "id": "role",
            "reduce": function reduce(role) {
              return [{
                label: role.label,
                role_id: role.id
              }];
            },
            "options": _vm.form.roles,
            "state": errors.length > 0 ? false : null
          },
          on: {
            "input": _vm.onChangeRoles
          },
          model: {
            value: _vm.form.add.roles,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "roles", $$v);
            },
            expression: "form.add.roles"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3220495083)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sub Hak Akses",
      "label-for": "subrole"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Sub Hak Akses",
      "vid": "subrole"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          attrs: {
            "id": "subrole",
            "multiple": "",
            "reduce": function reduce(subrole) {
              return {
                label: subrole.label,
                ability_id: subrole.id,
                id: subrole.id
              };
            },
            "options": _vm.form.subroles,
            "state": errors.length > 0 ? false : null
          },
          on: {
            "input": _vm.onChangeSubRoles
          },
          model: {
            value: _vm.form.add.subroles,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "subroles", $$v);
            },
            expression: "form.add.subroles"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3268043056)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kata Sandi",
      "label-for": "password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Password",
      "vid": "Password",
      "rules": "required|min:8"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          attrs: {
            "id": "password",
            "type": _vm.password1FieldType,
            "name": "password",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.add.password,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "password", $$v);
            },
            expression: "form.add.password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password1ToggleIcon
          },
          on: {
            "click": _vm.togglePassword1Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.newPassword(errors[0])))])];
      }
    }], null, false, 569890772)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Konfirmasi Kata Sandi",
      "label-for": "confirm-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Confirm password",
      "vid": "confirm-password",
      "rules": "required|confirmed:Password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          attrs: {
            "id": "confirm-password",
            "type": _vm.password2FieldType,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.add.confirmPassword,
            callback: function callback($$v) {
              _vm.$set(_vm.form.add, "confirmPassword", $$v);
            },
            expression: "form.add.confirmPassword"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password2ToggleIcon
          },
          on: {
            "click": _vm.togglePassword2Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1884054)
  })], 1)], 1)], 1), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.newUserLoading
    },
    domProps: {
      "textContent": _vm._s(_vm.newUserLoading ? 'Menyimpan...' : 'Simpan')
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "edit-user",
      "title": "Ubah User",
      "body-class": "py-2",
      "hide-footer": "",
      "size": "lg"
    }
  }, [_c('b-card-text', [_c('validation-observer', {
    ref: "editUserForm"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onUpdate.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan",
      "label-for": "perusahaan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Perusahaan",
      "vid": "perusahaan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "perusahaan",
            "type": "text",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.edit.perusahaan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "perusahaan", $$v);
            },
            expression: "form.edit.perusahaan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 459646692)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Name",
      "vid": "name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.edit.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "full_name", $$v);
            },
            expression: "form.edit.full_name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3148380456)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "vid": "email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "type": "email",
            "autocomplete": "off",
            "placeholder": "user@sal.com",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.edit.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "email", $$v);
            },
            expression: "form.edit.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3836195615)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor HP",
      "label-for": "phone"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor HP",
      "vid": "phone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "phone",
            "type": "number",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.edit.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "phone", $$v);
            },
            expression: "form.edit.phone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3308697978)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Group Account",
      "label-for": "group_account"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "group_account",
      "vid": "group_account"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('vue-autosuggest', {
          attrs: {
            "suggestions": [{
              data: _vm.form.groups
            }],
            "limit": 7,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Group Account'
            },
            "state": errors.length > 0 ? false : null,
            "get-suggestion-value": _vm.getSuggestionValueEdit
          },
          on: {
            "input": _vm.searchGroups
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var suggestion = _ref16.suggestion;
              return [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.name))])];
            }
          }], null, true),
          model: {
            value: _vm.form.edit.group_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "group_name", $$v);
            },
            expression: "form.edit.group_name"
          }
        })];
      }
    }], null, false, 1783380151)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type Account",
      "label-for": "type_account"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "type_account",
      "reduce": function reduce(type_account) {
        return type_account.label;
      },
      "options": _vm.form.type_account
    },
    model: {
      value: _vm.form.edit.type_account,
      callback: function callback($$v) {
        _vm.$set(_vm.form.edit, "type_account", $$v);
      },
      expression: "form.edit.type_account"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hak Akses",
      "label-for": "role"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Hak Akses",
      "vid": "role",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "role",
            "reduce": function reduce(role) {
              return [{
                label: role.label,
                role_id: role.id
              }];
            },
            "options": _vm.form.roles,
            "state": errors.length > 0 ? false : null
          },
          on: {
            "input": _vm.onChangeRoles
          },
          model: {
            value: _vm.form.edit.roles,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "roles", $$v);
            },
            expression: "form.edit.roles"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 959331798)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sub Hak Akses",
      "label-for": "subrole"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Sub Hak Akses",
      "vid": "subrole",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "subrole",
            "multiple": "",
            "reduce": function reduce(subrole) {
              return {
                label: subrole.label,
                ability_id: subrole.id,
                id: subrole.id
              };
            },
            "options": _vm.form.subroles,
            "state": errors.length > 0 ? false : null
          },
          on: {
            "input": _vm.onChangeSubRoles
          },
          model: {
            value: _vm.form.edit.subroles,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "subroles", $$v);
            },
            expression: "form.edit.subroles"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3508156621)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kata Sandi ( kosongkan jika tidak ingin mengubah )",
      "label-for": "password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Password",
      "vid": "Password",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          attrs: {
            "id": "password",
            "type": _vm.password1FieldType,
            "name": "password",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.edit.new_password,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "new_password", $$v);
            },
            expression: "form.edit.new_password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password1ToggleIcon
          },
          on: {
            "click": _vm.togglePassword1Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.newPassword(errors[0])))])];
      }
    }], null, false, 783693322)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Konfirmasi Kata Sandi",
      "label-for": "confirm-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Confirm password",
      "vid": "confirm-password",
      "rules": {
        required: _vm.form.edit.new_password ? true : false,
        confirmed: 'Password'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          attrs: {
            "id": "confirm-password",
            "type": _vm.password2FieldType,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.edit.confirmPassword,
            callback: function callback($$v) {
              _vm.$set(_vm.form.edit, "confirmPassword", $$v);
            },
            expression: "form.edit.confirmPassword"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password2ToggleIcon
          },
          on: {
            "click": _vm.togglePassword2Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3317126475)
  })], 1)], 1)], 1), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.editUserLoading
    },
    domProps: {
      "textContent": _vm._s(_vm.editUserLoading ? 'Menyimpan...' : 'Simpan Perubahan')
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "new-data-excel",
      "title": "Tambah Account ( Jalur Darat )",
      "cancel-variant": "secondary",
      "ok-title": "Simpan",
      "cancel-title": "Batal",
      "ok-variant": "success",
      "body-class": "py-2",
      "footer-class": "d-flex justify-content-center",
      "centered": ""
    },
    on: {
      "ok": function ok() {
        return _vm.onCreateWithExcel('darat');
      }
    }
  }, [_c('b-card-text', {
    staticClass: "text-center"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-warning",
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "TruckIcon"
    }
  })], 1), _c('label', {
    staticClass: "ml-1 mr-1",
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v(" Menambah Data Account Sekaligus (Banyak) ")]), _c('b-avatar', {
    attrs: {
      "variant": "light-warning",
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "TruckIcon"
    }
  })], 1), _c('b-form-group', [_c('v-dropzone', {
    ref: "files",
    attrs: {
      "id": "files",
      "options": {
        url: _vm.BACKEND_URL,
        method: "post",
        maxFilesize: 5,
        maxFiles: 0,
        chunking: true,
        acceptedFiles: "application/vnd.ms-excel,excel,spreadsheetml,.xlsx",
        autoProcessQueue: false
      },
      "duplicate-check": "",
      "use-custom-slot": "",
      "add-remove-links": ""
    },
    on: {
      "vdropzone-file-added": _vm.addFile,
      "vdropzone-removed-file": _vm.removeFile,
      "vdropzone-complete": _vm.afterComplete
    }
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v("Masukkan File ACCOUNT_JALUR_DARAT.xlsx")])])], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "new-data-excel-river",
      "title": "Tambah Account ( Jalur Sungai )",
      "cancel-variant": "secondary",
      "ok-title": "Simpan",
      "cancel-title": "Batal",
      "ok-variant": "success",
      "body-class": "py-2",
      "footer-class": "d-flex justify-content-center",
      "centered": ""
    },
    on: {
      "ok": function ok() {
        return _vm.onCreateWithExcel('sungai');
      }
    }
  }, [_c('b-card-text', {
    staticClass: "text-center"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-primary",
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "AnchorIcon"
    }
  })], 1), _c('label', {
    staticClass: "ml-1 mr-1",
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v(" Menambah Data Account Sekaligus (Banyak) ")]), _c('b-avatar', {
    attrs: {
      "variant": "light-primary",
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "AnchorIcon"
    }
  })], 1), _c('b-form-group', [_c('v-dropzone', {
    ref: "files",
    attrs: {
      "id": "files",
      "options": {
        url: _vm.BACKEND_URL,
        method: "post",
        maxFilesize: 5,
        maxFiles: 0,
        chunking: true,
        acceptedFiles: "application/vnd.ms-excel,excel,spreadsheetml,.xlsx",
        autoProcessQueue: false
      },
      "duplicate-check": "",
      "use-custom-slot": "",
      "add-remove-links": ""
    },
    on: {
      "vdropzone-file-added": _vm.addFile,
      "vdropzone-removed-file": _vm.removeFile,
      "vdropzone-complete": _vm.afterComplete
    }
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v("Masukkan File ACCOUNT_JALUR_SUNGAI.xlsx")])])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }